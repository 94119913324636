























































































































































  import { debounceInput } from '@/shared/helpers'
  import { Company, ICompany } from '@/shared/model/company.model'
  import { User } from '@/shared/model/user.model'
  import companyService from '@/shared/services/companyService'
  import axios from 'axios'
  import Vue from 'vue'

  export default Vue.extend({
    name: 'DashboardDataTables',

    components: {
      CompanyWizard: () => import('@/views/app/company/CompanyWizard.vue'),
    },

    data () {
      return {
        options: {} as any,

        dialog: false,
        selectedUser: new User(),

        search: '',
        companies: [] as Company[],
        totalCompanies: 0,
        createCompanyDialog: false,
        debounce: undefined as number | undefined,
      }
    },

    computed: {
      headers (): any {
        return [
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('email'),
            value: 'email',
          },
          {
            text: this.$t('employees'),
            value: 'users',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      options: {
        async handler () {
          await this.getAllCompaniesWithVDataTable()
        },
      },
      search: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllCompaniesWithVDataTable)
          this.options.page = 1
        },
      },
    },
    methods: {
      async getAllCompaniesWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0],
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.search },
        }
        await this.getList(query)
      },
      async getList ({ l, lo, sf, so, search = {} }: any) {
        try {
          // The actual limitOffset is calculated in the api
          const result = await axios.get('/api/companies', {
            params: { l: l, lo: lo - 1, sf, so, ...search },
          })
          if (result && result.data && result.data.items) {
            this.companies = result.data.items
            this.totalCompanies = result.data.total
          }
        } catch (err) {
          console.error(err)
        } finally {
        }
      },
      companyCreated () {
        this.createCompanyDialog = false
        this.getAllCompaniesWithVDataTable()
      },
      clickUpdateCompany (item: ICompany) {
        this.$router.push(`/app/companies/${item._id}`, () => {})
      },
      clickDeleteCompany (item: ICompany) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-company-to')}
<strong>${item.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('delete-company').toString(),
          },
        ).then((res) => {
          if (res) {
            if (item._id) {
              companyService.deleteCompany(item._id).then((result) => {
                if (result) {
                  this.getAllCompaniesWithVDataTable()
                }
              })
            } else {
              console.error('Tried to delete user without id, this should not happen')
            }
          }
        })
      },
      clickSendCompanyActivationLinkAgain (company: ICompany) {
        companyService.sendCompanyActivationLinkAgain(company).then((result) => {
          this.getAllCompaniesWithVDataTable()
        })
      },
    },
  })
