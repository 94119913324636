var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-account","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('companies'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"server-items-length":_vm.totalCompanies,"headers":_vm.headers,"items":_vm.companies,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{class:row.item.activated ? '' : 'opacity',attrs:{"data-label":_vm.headers[0].text}},[_vm._v(" "+_vm._s(row.item.name)+" ")]),_c('td',{class:row.item.activated ? '' : 'opacity',attrs:{"data-label":_vm.headers[1].text}},[_vm._v(" "+_vm._s(row.item.email)+" ")]),_c('td',{class:row.item.activated ? '' : 'opacity',attrs:{"data-label":_vm.headers[2].text}},[_vm._v(" "+_vm._s(row.item.users ? row.item.users.length : 0)+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.headers[3].text}},[(!row.item.activated)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.clickSendCompanyActivationLinkAgain(row.item)}}},on),[_c('v-icon',[_vm._v(" mdi-send ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.send-invitation-again')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.clickUpdateCompany(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-company')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){return _vm.clickDeleteCompany(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-company')))])])],1)])]}}])}),_c('v-divider'),_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":function($event){_vm.createCompanyDialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-company')))])])],1)],1),_c('v-dialog',{model:{value:(_vm.createCompanyDialog),callback:function ($$v) {_vm.createCompanyDialog=$$v},expression:"createCompanyDialog"}},[(_vm.createCompanyDialog)?_c('company-wizard',{attrs:{"create-as-admin":""},on:{"wizardcanceled":function($event){_vm.createCompanyDialog = false},"wizardfinished":_vm.companyCreated}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }